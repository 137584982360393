body {
    background: #ffffff;
    font-family: 'Depixel', monospace;
}

@-webkit-keyframes move-bg {
    0% {background-position: 0% 0%; }
    100% {background-position: 100px 0%; }
}
header {
    display: flex;
    justify-content: space-between;
}
.logo {
    position: fixed; /* sorry flex D: */
    scale: 3;
    left: 33%;
    top: 20%;
    z-index: -1;
}
pre {
    background: url("../img/rotated.svg"); -webkit-background-clip: text; -webkit-text-fill-color: transparent;
    -webkit-animation: move-bg;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 0.01s;
    text-shadow: none;
    font-family: monospace;
    scale: 0.65;
    margin: 0;
    padding: 0;
}

nav {
    color: #858585;
    padding: 10px;
    display: flex;
}

nav a {
    color: #a2a2a2;
    text-decoration: none;
}

nav a:hover {
    cursor: pointer;
    color: #67ea8e;
    text-decoration: underline;
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav ul li {
    margin: 0 1rem;
}

.window {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 55%;
    border: 1px solid #000;
    background: rgba(191, 191, 191, 0.93);;
    overflow: hidden;
    font-size: .7rem;
    line-height: 1.2rem;
    padding-bottom: 59px;
}

.window-content {
    color: #303030;;
    height: 100%;
    overflow: scroll;
    padding: 10px 100px;
    border-top: 1px solid #000;
    text-align: justify ;
}

.window-top {
    cursor: move;
    background-color: #8DFF9F;
    display: flex;
}
.window-top > span.left {
    margin-left: auto;
    width: 90%;
}
.window-top > span.right {
    margin-right: auto;
    text-align: right;
    width: 10%;
}
.window-shell {
    background-color: #333333;
    color: white;
}


/**
* Actual numbered references
* 1. Display the current state of the counter (e.g. `[1]`)
* 2. Align text as superscript
* 3. Make the number smaller (since it's superscript)
* 4. Slightly offset the number from the text
* 5. Reset link styles on the number to show it's usable
*/
[aria-describedby="footnote-label"]::after {
    content: '[' counter(footnotes) ']'; /* 1 */
    vertical-align: super; /* 2 */
    font-size: 0.5em; /* 3 */
    margin-left: 2px; /* 4 */
    color: blue; /* 5 */
    text-decoration: underline; /* 5 */
    cursor: pointer; /* 5 */
}

/**
* Resetting the default focused styles on the number
*/
[aria-describedby="footnote-label"]:focus::after {
    outline: thin dotted;
    outline-offset: 2px;
}

[aria-label="Back to content"] {
    font-size: 0.8em;
}

/**
* Highlight target note
*/
footer :target {
    background: yellow;
}

/**
* Visually hidden yet accessible content
*/
.visually-hidden {
    position: absolute;
    clip: rect(0 0 0 0);
    visibility: hidden;
    opacity: 0;
}

.collapsible {
    background-color: #eee;
    flex: 1;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
    background-color: #ccc;
    cursor: pointer;
}

/* Style the collapsible content. Note: hidden by default */
.collapsible-content {
    display: none;
    overflow: hidden;
    padding-left: 10px;
}