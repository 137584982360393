body {
  background: #fff;
  font-family: Depixel, monospace;
}

@-webkit-keyframes move-bg {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100px 0;
  }
}

header {
  justify-content: space-between;
  display: flex;
}

.logo {
  z-index: -1;
  position: fixed;
  top: 20%;
  left: 33%;
  scale: 3;
}

pre {
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  background: url("rotated.aa817052.svg");
  -webkit-background-clip: text;
  margin: 0;
  padding: 0;
  font-family: monospace;
  -webkit-animation: 2s linear 10ms infinite move-bg;
  scale: .65;
}

nav {
  color: #858585;
  padding: 10px;
  display: flex;
}

nav a {
  color: #a2a2a2;
  text-decoration: none;
}

nav a:hover {
  cursor: pointer;
  color: #67ea8e;
  text-decoration: underline;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

nav ul li {
  margin: 0 1rem;
}

.window {
  background: #bfbfbfed;
  border: 1px solid #000;
  width: 600px;
  height: 55%;
  padding-bottom: 59px;
  font-size: .7rem;
  line-height: 1.2rem;
  position: fixed;
  top: 40%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.window-content {
  color: #303030;
  text-align: justify;
  border-top: 1px solid #000;
  height: 100%;
  padding: 10px 100px;
  overflow: scroll;
}

.window-top {
  cursor: move;
  background-color: #8dff9f;
  display: flex;
}

.window-top > span.left {
  width: 90%;
  margin-left: auto;
}

.window-top > span.right {
  text-align: right;
  width: 10%;
  margin-right: auto;
}

.window-shell {
  color: #fff;
  background-color: #333;
}

[aria-describedby="footnote-label"]:after {
  content: "[" counter(footnotes) "]";
  vertical-align: super;
  color: #00f;
  cursor: pointer;
  margin-left: 2px;
  font-size: .5em;
  text-decoration: underline;
}

[aria-describedby="footnote-label"]:focus:after {
  outline-offset: 2px;
  outline: thin dotted;
}

[aria-label="Back to content"] {
  font-size: .8em;
}

footer :target {
  background: #ff0;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.collapsible {
  background-color: #eee;
  flex: 1;
}

.active, .collapsible:hover {
  cursor: pointer;
  background-color: #ccc;
}

.collapsible-content {
  padding-left: 10px;
  display: none;
  overflow: hidden;
}
/*# sourceMappingURL=index.8c4cc494.css.map */
